<template>
  <div>
    <div
      class="AddItem card shadow-brut transition duration-200 hover:shadow-brutHoverPurple border border-black relative my-10 mx-auto"
    >
      <label
        for="myFile"
        class="cursor-pointer border-2 border-black callToAction delete bg-white rounded-full border-4 absolute -top-7 -left-7 border-black shadow-brutButton  flex justify-center items-center "
      >
        <span class="material-icons text-4xl">add_photo_alternate</span></label
      >
      <input
        type="file"
        name="myFile"
        id="myFile"
        @change="onFileChange"
        class="hidden"
      />
      <div
        class="z-index--1 absolute w-full h-1/4 bg-cover filter brightness-75"
        :style="{ backgroundImage: 'url(' + this.image + ')' }"
      >
        <!-- <img :src="image" alt="" class="h-2/6 w-full opacity-50 filter brightness-50"/> -->
      </div>
      <input
        class="text-center py-10 text-4xl font-extrabold title placeholder-purple-200 w-full bg-transparent text-purple-400"
        v-model="title"
        type="text"
        name=""
        placeholder="Title of Project"
      />
      <textarea
        class="resize-none block mt-1 w-9/12 h-1/6 pr-5 pl-2 m-auto text-base text-justify overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100 text-purple-500 focus:bg-transparent placeholder-purple-300 bg-gray-50"
        v-model="content"
        type="text"
        name=""
        placeholder="Description of Project"
      />
      <input
        type="text"
        class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-purple-500 focus:bg-transparent placeholder-purple-200 pl-2"
        placeholder="www.example.com"
        v-model="link"
      />
      <input
        type="text"
        class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-purple-500 focus:bg-transparent placeholder-purple-200 pl-2"
        placeholder="e.x : 2021"
        v-model="year"
      />
      <input
        type="text"
        class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-purple-500 focus:bg-transparent placeholder-purple-200 pl-2"
        placeholder="e.x : Digitaform"
        v-model="client"
      />
      <input
        type="text"
        class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-purple-500 focus:bg-transparent placeholder-purple-200 pl-2"
        placeholder="e.x : Developpement web"
        v-model="lineOfBusiness"
      />
      <input
        type="text"
        class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-purple-500 focus:bg-transparent placeholder-purple-200 pl-2"
        placeholder="e.x : Refonte du site"
        v-model="delivered"
      />
      <div
        class="callToAction delete bg-white rounded-full border-4 absolute -bottom-3 -right-3 border-black shadow-brutButton cursor-pointer flex justify-center items-center"
        @click="addItem"
      >
        <span class="material-icons text-4xl">
          add
        </span>
      </div>
    </div>
    <!-- LIST OF PROJECTS -->
    <div class="flex flex-row flex-wrap justify-around py-10">
      <div
        class="card shadow-brut border border-black relative mb-5 transition duration-200"
        :class="
          project.status
            ? 'hover:shadow-brutHoverGreen'
            : 'hover:shadow-brutHoverRed'
        "
        v-for="(project, i) in projects"
        :key="project.titre"
      >
        <!-- Image -->
        <!-- <img :src="project.image" alt="" class="w-full after" /> -->

        <div
          class="z-index--1 absolute w-full h-1/4 bg-cover filter brightness-75"
          :style="
            isSelected(project)
              ? { backgroundImage: 'url(' + this.imageUpdate + ')' }
              : { backgroundImage: 'url(' + project.image + ')' }
          "
        ></div>
        <!-- Project Title -->
        <input
          class="inputCustom text-gray-200 text-center py-10 text-4xl font-extrabold title w-full bg-transparent"
          v-if="isSelected(project)"
          v-model="titleUpdate"
          type="text"
          name=""
        />
        <div
          v-else
          type="text"
          class="project-titre text-center py-10 text-4xl font-extrabold title text-white bg-transparent"
          :placeholder="project.titre"
        >
          {{ project.titre }}
        </div>
        <!-- Project Content -->
        <textarea
          class="block w-9/12 h-1/6 pr-5 mt-1 m-auto text-base text-justify overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100 box-border resize-none text-gray-400"
          v-if="isSelected(project)"
          v-model="contentUpdate"
          type="text"
          name=""
        />
        <div
          v-else
          class="w-9/12 h-1/6 pr-5 m-auto text-base text-justify overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100"
        >
          {{ project.content }}
        </div>
        <!-- Project link -->
        <input
          class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-gray-600 focus:bg-transparent placeholder-gray-300 font-bold"
          v-if="isSelected(project)"
          v-model="linkUpdate"
          type="link"
          name=""
          placeholder="www.example.com"
        />
        <div
          v-else
          class="w-9/12 mt-4 pr-5 m-auto text-base text-justify overflow-y-auto font-bold"
        >
          {{ project.link }}
        </div>
        <!-- Project year -->
        <input
          class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-gray-600 focus:bg-transparent placeholder-gray-300 font-bold"
          v-if="isSelected(project)"
          v-model="yearUpdate"
          type="text"
          name=""
          placeholder="ex: 2021"
        />
        <div
          v-else
          class="w-9/12 mt-4 pr-5 m-auto text-base text-justify overflow-y-auto font-bold"
        >
          {{ project.year }}
        </div>
        <!-- Project client -->
        <input
          class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-gray-600 focus:bg-transparent placeholder-gray-300 font-bold"
          v-if="isSelected(project)"
          v-model="clientUpdate"
          type="text"
          name=""
          placeholder="ex: Digitaform"
        />
        <div
          v-else
          class="w-9/12 mt-4 pr-5 m-auto text-base text-justify overflow-y-auto font-bold"
        >
          {{ project.client }}
        </div>
        <!-- Project lineOfBusiness -->
        <input
          class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-gray-600 focus:bg-transparent placeholder-gray-300 font-bold"
          v-if="isSelected(project)"
          v-model="lineOfBusinessUpdate"
          type="text"
          name=""
          placeholder="ex: Digitaform"
        />
        <div
          v-else
          class="w-9/12 mt-4 pr-5 m-auto text-base text-justify overflow-y-auto font-bold"
        >
          {{ project.lineOfBusiness }}
        </div>
        <!-- Project delivered -->
        <input
          class="bg-gray-50 w-9/12 rounded block m-auto mt-4 text-gray-600 focus:bg-transparent placeholder-gray-300 font-bold"
          v-if="isSelected(project)"
          v-model="deliveredUpdate"
          type="text"
          name=""
          placeholder="ex: Digitaform"
        />
        <div
          v-else
          class="w-9/12 mt-4 pr-5 m-auto text-base text-justify overflow-y-auto font-bold"
        >
          {{ project.delivered }}
        </div>
        <!-- eye : change the status -->
        <div
          class="callToAction delete bg-white rounded-full border-4 absolute -top-3 -left-3 border-black shadow-brutButton cursor-pointer flex justify-center items-center"
          @click="toggleUpload(project, i)"
        >
          <span
            class="material-icons text-4xl"
            v-html="project.status ? 'visibility' : 'visibility_off'"
          ></span>
        </div>

        <!-- close or deleteDemand -->
        <div
          class="callToAction delete bg-white rounded-full border-4 absolute -bottom-3 -right-3 border-black shadow-brutButton cursor-pointer flex justify-center items-center"
          @click="
            isSelected(project) || isSelectedForDelete(project)
              ? unselect()
              : selectForDelete(project)
          "
        >
          <span
            class="material-icons text-4xl"
            v-html="
              isSelected(project) || isSelectedForDelete(project)
                ? 'close'
                : 'delete'
            "
          ></span>
        </div>
        <!--  delete -->
        <div
          class="callToAction delete bg-white rounded-full border-4 absolute -top-3 -right-3 border-black shadow-brutButton cursor-pointer flex justify-center items-center"
          :class="isSelectedForDelete(project) ? 'block' : 'hidden'"
          @click="deleteItem(project, i)"
        >
          <span
            class="material-icons text-4xl"
            v-html="isSelected(project) ? 'close' : 'delete'"
          ></span>
        </div>
        <!-- Save change -->
        <div
          class="callToAction delete bg-white rounded-full border-4 absolute -bottom-3 -left-3 border-black shadow-brutButton cursor-pointer flex justify-center items-center"
          @click="
            isSelected(project) ? updateProject(project, i) : select(project)
          "
        >
          <span
            class="material-icons text-4xl"
            v-html="isSelected(project) ? 'save' : 'edit'"
          ></span>
        </div>
        <!-- Change Image -->
        <div>
          <label
            for="updateFile"
            :class="
              isSelected(project)
                ? 'cursor-pointer border-2 border-black callToAction delete bg-white rounded-full border-4 absolute -top-3 -right-3 border-black shadow-brutButton  flex justify-center items-center'
                : 'hidden'
            "
          >
            <span class="material-icons text-4xl"
              >add_photo_alternate</span
            ></label
          >
          <input
            type="file"
            name="myFile"
            :id="isSelected(project) ? 'updateFile' : ''"
            @change="onImageUpdate"
            class="hidden"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { urlApi } from "./config";

export default {
  name: "App",
  data() {
    return {
      projects: [],
      title: "",
      content: "",
      link: "",
      image: "",
      year: "",
      client: "",
      lineOfBusiness: "",
      delivered: "",
      titleUpdate: "",
      contentUpdate: "",
      linkUpdate: "",
      imageUpdate: "",
      yearUpdate: "",
      clientUpdate: "",
      lineOfBusinessUpdate: "",
      deliveredUpdate: "",
      selected: {},
      selectedForDelete: {},
    };
  },
  methods: {
    async addItem() {
      const reponse = await axios.post(
        "https://portfolioController.r-selwa.space/api/projects",
        {
          titre: this.title,
          content: this.content,
          image: this.image,
          link: this.link,
          year: this.year,
          client: this.client,
          lineOfBusiness: this.lineOfBusiness,
          delivered: this.delivered,
        }
      );
      this.projects.push(reponse.data);
      this.title = "";
      this.content = "";
      this.link = "";
      this.image = "";
      this.year = "";
      this.client = "";
      this.lineOfBusiness = "";
      this.delivered = "";
    },
    onFileChange(e) {
      if (this.image == "") {
        var files = e.target.files;
        if (!files.length) {
          return;
        }
        this.createImage(files[0]);
      } else {
        this.image = "";
      }
    },
    onImageUpdate(e) {
      var files = e.target.files;
      if (!files.length) {
        return;
      }
      this.updateImage(files[0]);
    },
    updateImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.imageUpdate = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    unselect() {
      this.selected = {};
      this.selectedForDelete = {};
      this.titleUpdate = "";
      this.contentUpdate = "";
      this.linkUpdate = "";
      this.yearUpdate = "";
      this.clientUpdate = "";
      this.lineOfBusinessUpdate = "";
      this.deliveredUpdate = "";
    },
    select(item) {
      if (this.isSelectedForDelete(item)) {
        this.selectedForDelete = {};
      }
      this.selected = item;
      this.titleUpdate = item.titre;
      this.contentUpdate = item.content;
      this.linkUpdate = item.link;
      this.imageUpdate = item.image;
      this.yearUpdate = item.year;
      this.clientUpdate = item.client;
      this.lineOfBusinessUpdate = item.lineOfBusiness;
      this.deliveredUpdate = item.delivered;
    },
    isSelected(item) {
      return this.selected._id === item._id;
    },
    unselectForDelete() {
      this.selectForDelete = {};
    },
    selectForDelete(item) {
      this.selectedForDelete = item;
    },
    isSelectedForDelete(item) {
      return this.selectedForDelete._id === item._id;
    },
    async deleteItem(project, i) {
      await axios.delete(
        urlApi + "/api/projects/" + project._id
        // process.env.VUE_APP_PATH_API + "/api/projects/" + project._id

        // "http://portfoliocontroller.r-selwa.space/api/projects/" + project._id
      );
      this.projects.splice(i, 1);
    },
    async updateProject(project, i) {
      const response = await axios.put(
        urlApi + "/api/projects/" + project._id,
        // process.env.VUE_APP_PATH_API + "/api/projects/" + project._id,

        // "http://portfoliocontroller.r-selwa.space/api/projects/" + project._id,
        {
          titre: this.titleUpdate,
          content: this.contentUpdate,
          link: this.linkUpdate,
          status: project.status,
          image: this.imageUpdate,
          client: this.clientUpdate,
          year: this.yearUpdate,
          lineOfBusiness: this.lineOfBusinessUpdate,
          delivered: this.deliveredUpdate,
        }
      );
      this.projects[i] = response.data;
      this.unselect();
    },
    async toggleUpload(project, i) {
      const response = await axios.put(
        urlApi + "/api/projects/" + project._id,
        // process.env.VUE_APP_PATH_API + "/api/projects/" + project._id,

        // "http://portfoliocontroller.r-selwa.space/api/projects/" + project._id,
        {
          titre: project.title,
          content: project.content,
          status: !project.status,
        }
      );
      this.projects[i] = response.data;
    },
  },
  async mounted() {
    const reponse = await axios.get(
      urlApi + "/api/projects/"
      // process.env.VUE_APP_PATH_API + "/api/projects/"

      // "http://portfoliocontroller.r-selwa.space/api/projects/"
    );
    this.projects = reponse.data;
  },
};
</script>

<style lang="scss">
svg {
  fill: red;
  width: 70%;
  stroke-width: 50px;
}
.card {
  width: 324px;
  height: 437px;
  // z-index: -2;
  img {
    z-index: -1;
    left: 0;
    top: 0;
    position: absolute;
  }
  .project-titre {
    z-index: 3;
  }
  ::before {
    background-color: black;
    bottom: 50px;
    left: 20px;
  }
}
.title {
  -webkit-text-stroke: 2px black;
  text-shadow: 3px 3px 0px black;
}
.callToAction {
  width: 74px;
  height: 74px;
}
.contentUpdate {
  margin: 0 auto;
  width: 400px;
}

.z-index--1 {
  z-index: -1;
}
</style>
